<template>
  <div class="home">
    <b-row v-if="true">
      <b-col
        cols="12"
        sm="4"
        md="3"
        v-if="
          hasAccess('Self Service Request') ||
          hasAccess('ACL Triggers') ||
          hasAccess('Job Cards') ||
          hasAccess('Quotes')
        "
      >
        <div v-if="hasAccess('Self Service Request') || hasAccess('ACL Triggers')">
          <h4>Ticket Creation</h4>
          <hr />
          <b-card
            class="mb-3 menu-card-outline"
            @click="navigateTo('/acl/triggerSearch')"
            v-if="hasAccess('ACL Triggers')"
          >
            <span><b-icon-list-task variant=""></b-icon-list-task></span>
            ACL Triggers
          </b-card>
          <b-card
            class="mb-3 menu-card-outline"
            @click="navigateTo('/selfService/search')"
            v-if="hasAccess('Self Service Request')"
          >
            <span><b-icon-flag></b-icon-flag></span>
            Self Service Request
          </b-card>
        </div>

        <div v-if="hasAccess('Job Cards') || hasAccess('Quotes')">
          <h4
            :class="
              (hasAccess('Self Service Request') ||
                hasAccess('ACL Triggers') ||
                hasAccess('Job Cards') ||
                hasAccess('Quotes')) === true
                ? 'heading-space'
                : ''
            "
          >
            Job Card Management
          </h4>
          <hr />
          <b-card class="mb-3 menu-card-outline" @click="navigateTo('/jobcards/search')" v-if="hasAccess('Job Cards')">
            <span><b-icon-clipboard></b-icon-clipboard></span>
            Job Cards
          </b-card>
          <b-card class="mb-3 menu-card-outline" @click="navigateTo('/quotes/search')" v-if="hasAccess('Quotes')">
            <span><b-icon-file-earmark></b-icon-file-earmark></span>
            Quotations
          </b-card>
        </div>
      </b-col>
      <b-col
        cols="12"
        sm="4"
        md="3"
        v-if="
          hasAccess('Checklist Management') ||
          hasAccess('Checklist Scheduling') ||
          hasAccess('Timesheet Submission Management') ||
          hasAccess('Survey Management') ||
          hasAccess('PPE Management')
        "
      >
        <h4>Sectional Management</h4>
        <hr />
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/checklist/search')"
          v-if="hasAccess('Checklist Management')"
        >
          <span><b-icon-check-circle></b-icon-check-circle></span>
          Manage Checklists
        </b-card>
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/checklist/schedule/search')"
          v-if="hasAccess('Checklist Scheduling')"
        >
          <span><b-icon-alarm></b-icon-alarm></span>
          Schedule Checklist
        </b-card>
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/ppe/current/search')"
          v-if="hasAccess('PPE Management')"
        >
          <span><b-icon-box-arrow-in-down></b-icon-box-arrow-in-down></span>
          Manage PPE Requests
        </b-card>
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/ppe/edit')" v-if="hasAccess('PPE Management')">
          <span><b-icon-box-arrow-in-down></b-icon-box-arrow-in-down></span>
          Edit PPE Request Form
        </b-card>
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/survey/search')"
          v-if="hasAccess('Survey Management')"
        >
          <span><b-icon-clipboard-data></b-icon-clipboard-data></span>
          Surveys
        </b-card>
        <!-- v-if="hasAccess('Timesheet Submission Management')" -->
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/timesheets/listSubmissions')"
          v-if="hasAccess('Timesheet Submission Management')"
        >
          <span><b-icon-layout-text-window></b-icon-layout-text-window></span>
          Timesheet Submissions
        </b-card>
      </b-col>
      <b-col cols="12" sm="4" md="3">
        <h4>Self Management</h4>
        <hr />
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/checklist/complete')" v-if="hasAccess('Complete Checklist')">
          <span><b-icon-check-circle></b-icon-check-circle></span>
          Complete a Checklist
        </b-card>
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/checklist/completeToolBox')"
        v-if="hasAccess('Complete Toolbox Talk')">
          <span><b-icon-check-circle></b-icon-check-circle></span>
          Complete a Toolbox Talk
        </b-card>
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/ppe/history')">
          <span><b-icon-card-list></b-icon-card-list></span>
          Submit PPE Request
        </b-card>
        <b-card class="mb-3 menu-card-outline" 
            @click="navigateTo('/timesheets/view')"
            v-if="hasAccess('Timesheet Submission')">
          <span><b-icon-inbox></b-icon-inbox></span>
          Manage Timesheet
        </b-card>
        <!-- v-if="hasAccess('Timesheet Submission')" -->
        <b-card
          class="mb-3 menu-card-outline"
          @click="navigateTo('/timesheets/submitTimesheetList')"
          v-if="hasAccess('Timesheet Submission')"
        >
          <span><b-icon-folder-symlink></b-icon-folder-symlink></span>
          Submit Timesheet
        </b-card>
      </b-col>
    </b-row>

    <b-row
      v-if="
        hasAccess('Checklist Reports') ||
        hasAccess('Survey Management') ||
        hasAccess('Quotes Reporting') ||
        hasAccess('Timesheet Group Report') ||
        hasAccess('Timesheet Individual Report')
      "
      class="mb-3"
    >
      <b-col cols="12">
        <h4>Reports</h4>
        <hr />
      </b-col>
      <b-col cols="3" v-if="hasAccess('Checklist Reports')">
        <b-card class="menu-card-outline" @click="navigateTo('/complianceReport')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          Compliance Report
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Checklist Reports')">
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/checklist/completed')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          User Completed Checklists
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('All Completed Checklist Report')">
        <b-card class="mb-3 menu-card-outline" @click="navigateTo('/allchecklist/completed')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          All Completed Checklists
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Survey Management')">
        <b-card class="menu-card-outline" @click="navigateTo('/survey/summary')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          Survey Summary
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Quotes Reporting')">
        <b-card class="menu-card-outline" @click="navigateTo('/quotes/summary')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          Quotes Summary
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Timesheet Group Report')">
        <b-card class="menu-card-outline" @click="navigateTo('/timesheets/groupReport')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          Timesheet Group Report
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Timesheet Individual Report')">
        <b-card class="menu-card-outline" @click="navigateTo('/timesheets/individualReport')">
          <span><b-icon-file-earmark-text></b-icon-file-earmark-text></span>
          Timesheet Individual Report
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="hasAccess('Manage Products') || hasAccess('Manage Contacts') || hasAccess('Manage Users')">
      <b-col cols="12">
        <h4>Administration</h4>
        <hr />
      </b-col>
      <b-col cols="3" v-if="hasAccess('Self Service Request Admin')">
      <b-card
            class="mb-3 menu-card-outline"
            @click="navigateToForced('/selfService/search/admin')">
            <span><b-icon-flag></b-icon-flag></span>
            Self Service Request
          </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Manage Contacts')">
        <b-card class="menu-card-outline" @click="navigateTo('/contacts/contactSearch')">
          <span><b-icon-telephone></b-icon-telephone></span>
          Manage Contacts
        </b-card>
      </b-col>
      <b-col cols="3" v-if="hasAccess('Manage Products')">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/stockSearch')">
          <span><b-icon-box-seam></b-icon-box-seam></span>
          Manage Products
        </b-card>
      </b-col>
      <b-col cols="12" sm="4" md="3" v-if="hasAccess('Manage Users')">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/userSearch')">
          <span><b-icon-people></b-icon-people></span>
          Manage Users
        </b-card>
      </b-col>
      <b-col cols="12" sm="4" md="3" v-if="hasAccess('Manage Users')">
        <b-card class="menu-card-outline" @click="navigateTo('/admin/sherqMailList')">
          <span><b-icon-card-list></b-icon-card-list></span>
          Manage SHERQ Mail List
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';

export default {
  name: 'Home',
  data: () => ({
    rights: [],
  }),
  created() {
    this.setBreadcrumb([
      {
        text: 'Home',
        active: true,
      },
    ]),
      (this.rights = JSON.parse(localStorage.getItem('screenList')));
  },
  methods: {
    ...mapMutations('breadcrumbService', ['setBreadcrumb']),
    navigateTo(route) {
      this.$router.push({ path: route });
    },
    navigateToForced(route) {
      this.$router.push({ path: route });
    },
    hasAccess(screen) {
      for (let i = 0; i < this.rights.length; i++) {
        const element = this.rights[i];
        if (element.toLowerCase() === screen.toLowerCase()) {
          return true;
        }
      }

      return false;
    },
  },
};
</script>
